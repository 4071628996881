import { download, generateCsv, mkConfig } from "export-to-csv";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import PropTypes from "prop-types";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});



const handleExportData = (data) => {

  const csv = generateCsv(csvConfig)(data);
  download(csvConfig)(csv);
};

const MaterialDataGrid = ({
  columns,
  data,
  rowCount,
  isError,
  handleColumnFilter,
  handlePagination,
  handleSorting,
  initialState,
  pagination,
  enableEditing,
  enableExpandAll,
  enableExpanding,
  ref
}) => {
  const table = useMaterialReactTable({
    columns,
    data,
    rowCount,
    getRowId: (row) => row.id,
    
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,

    onColumnFiltersChange: handleColumnFilter,
    onPaginationChange: handlePagination,
    onSortingChange: handleSorting,
    state: initialState,
    initialState: { showColumnFilters: true },
    // localization: { noRecordsToDisplay: "Data Not Found" },
    localization: {
      noRecordsToDisplay: initialState?.isLoading
        ? "Loading......" // Show "Loading..." message when loading
        : data?.length === 0
        ? "No record found" // Show "No data found" message when data length is 0
        : undefined, // Use the default value when data is available
    },
    muiTableHeadCellProps: {
      //no useTheme hook needed, just use the `sx` prop with the theme callback
      sx: ({ theme, column }) => ({
        color: "white",
        background: "#222e3c",
        borderRight: "1px solid #ddd", // Add vertical line between columns
      }),
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "2px solid #ddd", //add a border between columns
      },
    },
    muiCircularProgressProps: {
      color: "secondary",
      thickness: 1,
      size: 55,
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 28,
    },

    enableStickyHeader: true,
    // muiTableContainerProps: { sx: { maxHeight: "100%" } },

    muiFilterTextFieldProps: {
      sx: ({ theme, column }) => ({
        color: "white",
        background: "white",
        borderBottom: "none",
      }),
    },

    muiTableSortLabelProps: {
      sx: ({ theme, column }) => ({
        color: "white",
      }),
    },
    // ----------------------- //

    enableColumnFilterModes: false,
    enablePagination: pagination,

    manualPagination: true,
    manualSorting: true,
    // paginationDisplayMode: "pages",
    rowNumberDisplayMode: "dynamic",
    enableRowSelection: false,
    enableTopToolbar: false,
    // enableRowNumbers: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    //columns filter
    enableFilters: false,
    manualFiltering: false,
    // ---------------- //
    enableGlobalFilter: false,
    enableColumnActions: false,

    // expand table start
    enableExpandAll: enableExpandAll,
    enableExpanding: enableExpanding,
    getSubRows: (row) => row.subRows, //default
    displayColumnDefOptions: {
      "mrt-row-expand": {
        enableResizing: false, //allow resizing
        size: 1, //make the expand column wider
      },
    },
    filterFromLeafRows: false,
    
  

    // expand table end
  });
  return <MaterialReactTable table={table}  />;
};

MaterialDataGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  isError: PropTypes.bool,
  handleColumnFilter: PropTypes.func,
  handleGlobalFilter: PropTypes.func,
  handlePagination: PropTypes.func,
  handleSorting: PropTypes.func,
  initialState: PropTypes.object, // adjust the shape as needed
  pagination: PropTypes.bool,
  enableEditing: PropTypes.bool,
  enableExpanding: PropTypes.bool,
  enableExpandAll: PropTypes.bool,
};

MaterialDataGrid.defaultProps = {
  columns: [],
  data: [],
  rowCount: 0,
  isError: false,
  handleColumnFilter: () => {},
  handleGlobalFilter: () => {},
  handlePagination: () => {},
  handleSorting: () => {},
  initialState: {}, // adjust the default state as needed
  pagination: true,
  enableEditing: false,
  enableExpandAll: false,
  enableExpanding: false,
};

export default MaterialDataGrid;
export { handleExportData };
