import React, { useRef ,useEffect } from 'react';



const Otp = ({otpLength}) => {
    const inputRefs = useRef([]);
    useEffect(() => {
      inputRefs.current[0].focus();
  }, []);
    const handleKeyDown = (index) => (event) => {
      if (!inputRefs.current[index].value && event.key === 'Backspace' && index > 0) {
            inputRefs.current[index - 1].focus();
        }
        if (
              !/^\d$/.test(event.key) && // Check if pressed key is not a digit
              event.key !== 'Backspace' && // Check if pressed key is not the backspace
              event.key !== 'Delete' // Check if pressed key is not the delete key
          ) {
              event.preventDefault();
          }
        
    };
    const handleInput = (index) => (event) => {
        const currentValue = event.target.value;
        if (currentValue && /^\d$/.test(currentValue)) {
          if (inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
          }
        }
    };
    const handlePaste = (ev) => {
        const clip = ev.clipboardData.getData('text').replaceAll(/\s/g, '').trim();
        if (!/\d{otpLength}/.test(clip)) return ev.preventDefault(); // Invalid. Exit here
        const otpArray = [...clip];
        otpArray.forEach((char, index) => {
          if (inputRefs.current[index]) {
            inputRefs.current[index].value = char;
            if (index === (otpLength-1)) inputRefs.current[index].focus();
          }
        });
    };

    return (
        <>
             {[...Array(otpLength)].map((_, index) => (
                <input
                key={index}
                ref={(ref) => (inputRefs.current[index] = ref)}
                className="otpInput input-number"
                onPaste={handlePaste}
                onInput={handleInput(index)}
                onKeyDown={handleKeyDown(index)}
                maxLength={1}
                autoFocus={index === 0}
                type='text'
                />
            ))}
        </>
    )
}
export default Otp;