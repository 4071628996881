export const UserFilterOption = [
  {
    label: "Guard code",
    value: 1,
  },
  {
    label: "Name",
    value: 2,
  },
  {
    label: "Email",
    value: 3,
  },
  {
    label: "Mobile No",
    value: 4,
  },
  {
    label: "Date of Joining",
    value: 5,
  },
  {
    label: "Role",
    value: 6,
  },
  {
    label: "Company",
    value: 7,
  },
  {
    label: "Designation",
    value: 8,
  },
  {
    label: "Location",
    value: 9,
  },
  {
    label: "Vendor Name",
    value: 10,
  },
  {
    label: "Shift Name",
    value: 11,
  },
  {
    label: "Status",
    value: 12,
  },
];


export const LocationFilterOption = [
  {
    label: "Location Name",
    value: 1,
  },
  {
    label: "Date",
    value: 2,
  },
];


export const DesiginationFilterOption = [
  {
    label: "Desigination Name ",
    value: 1,
  },
  {
    label: "Company Name",
    value: 2,
  },
  {
    label: "Location Name",
    value: 3,
  },
  {
    label: "Vendor Name",
    value: 4,
  },

];
export const VendorFilterOption = [
  {
    label: "Name",
    value: 1,
  },
  {
    label: "Email",
    value: 2,
  },
  {
    label: "Mobile No.",
    value: 3,
  },
  {
    label: "GST No.",
    value: 4,
  },
  {
    label: "TAN No.",
    value: 5,
  },
  {
    label: "PAN No.",
    value: 6,
  },
  {
    label: "EPFO No.",
    value: 7,
  },
  {
    label: "Security Certificate Number",
    value: 8,
  },

];

export const CheckpointFilterOption = [
  {
    label: "Company Name",
    value: 1,
  },
  {
    label: "Location Name",
    value: 2,
  },
    {
    label: "Created Date",
    value: 3,
  },
];

export const CompanyFilterOption = [
  {
    label: "Company Name",
    value: 1,
  },
  {
    label: "Location Name",
    value: 2,
  },
  {
    label: "Date",
    value: 3,
  },
  
];

export const CompanymasterFilterOption = [
  {
    label: "Company Name",
    value: 1,
  },
  {
    label: "Date",
    value: 3,
  },
  
];


export const RoleFilterOption = [
  {
    label: "Role Name",
    value: "name",
  },
  {
    label: "Status",
    value: "is_active",
  },
  {
    label: "Date",
    value: "created_at",
  },
  
];


export const ShiftFilterOption = [
  { 
    label: "Company Name",
    value: "company_name",
  },
  { 
    label: "Shift Name",
    value: "shift_name",
  },
  {
    label: "Start Time",
    value: "start_time",
  },
  {
    label: "End Time",
    value: "end_time",
  },
  {
    label: "Date",
    value: "date",
  },

  
];


export const AttendanceFilterOption= [
  { 
    label: "Guard Code",
    value: "guard_code",
  },
  { 
    label: "Name",
    value: "username",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location",
    value: "location_name",
  },
  {
    label: "Designation",
    value: "designation_name",
  },
  {
    label: "Date",
    value: "date",
  },

  
];


export const GatelockFilterOption= [
  { 
    label: "Guard Code",
    value: "guard_code",
  },
  { 
    label: "Name",
    value: "username",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location",
    value: "location_name",
  },
  {
    label: "Designation",
    value: "designation_name",
  },
  {
    label: "Date",
    value: "date",
  },

  
];


export const TresspasingFilterOption= [
  { 
    label: "Guard Code",
    value: "guard_code",
  },
  { 
    label: "Name",
    value: "username",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location",
    value: "location_name",
  },
  {
    label: "Designation",
    value: "designation_name",
  },
  {
    label: "Date",
    value: "date",
  },

];


export const SalaryFilterOption= [
  { 
    label: "Guard Code",
    value: "guard_code",
  },
  { 
    label: "Name",
    value: "username",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Location",
    value: "location_name",
  },
  {
    label: "Designation",
    value: "designation_name",
  },
  {
    label: "Vendor",
    value: "vendor_name",
  },
  {
    label: "Date of joining",
    value: "date_of_joining",
  },
  {
    label: "Present Month-Year",
    value: "month_year",
  },
  {
    label: "Year",
    value: "year",
  },
  {
    label: "Salary",
    value: "salary",
  },

];


// export const SalaryFilterOption= [
//   {
//     label: "Company Name",
//     value: "company_name",
//   },
//   {
//     label: "Location",
//     value: "location_name",
//   },  
//   {
//     label: "Vendor",
//     value: "vendor_name",
//   },
//   {
//     label: "Security Guard",
//     value: "security_guard",
//   },
// ];
