import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents, useMap } from "react-leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import L from "leaflet";
import "leaflet-control-geocoder";

const LocationMarker = ({setLocation}) => {
  useMapEvents({
    click: (e) => {
      const { lat, lng } = e.latlng;
      setLocation({ lat, lng });
    }
  });
  return null;
};



const UpdateMapView = ({ location }) => {
  const map = useMap();
  
  useEffect(() => {
    if (location) {
      map.setView([location.lat, location.lng], 20);
    }
  }, [location, map]);

  return null;
};
const DetectMyLocation = ({location,setLocation}) => {


  return (
    <MapContainer center={location} zoom={13} scrollWheelZoom={true} style={{ height: "100vh", width: "100%" }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={location} />
      <LocationMarker setLocation={setLocation}/>
      <UpdateMapView location={location} />
    </MapContainer>
  );
}

export default DetectMyLocation;
