import { createSlice } from "@reduxjs/toolkit";

const CommonFunction = createSlice({
  name: "CommonFunction",
  initialState: {
    sideBarToggle: false
  },
  reducers: {
    sideBarsToggleDispatch(state, action) {
        state.sideBarToggle = action.payload;
    },
  },
});


export const SelectSideBarToggles = (state) => state?.CommonFunction?.sideBarToggle;


export default CommonFunction.reducer;
export const { sideBarsToggleDispatch } = CommonFunction.actions;
