import React from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

export const BackButtonModal = ({
  isOpen,
  handleClose,
  toggleFormFillrter,
}) => {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ position: "elative", overflow: "hidden" }}>
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            are you sure, you want to leave without saving?
          </Typography>
          <div class="col-12 text-center mt-lg-5 mt-4">
          <button
          class="btn btn-lg btn-primary px-5"
            onClick={() => {
              toggleFormFillrter();
            }}
          >
            YES
          </button>
          <button
          class="btn btn-lg btn-primary px-5 mx-xl-4"
            onClick={() => {
              handleClose();
             
            }}
          >
            NO
          </button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};
