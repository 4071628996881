import axios from "axios";
export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
});
axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('accessToken')
    config.headers = {
        "Content-Type": "application/json",
    };
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const {
        response,
      } = error;
      if (response && response?.status === 500){
        localStorage.clear();     
        window.location.replace("/"); 
      }
      else if (response && response.status === 409) {
        // Do nothing for 409 errors
        return Promise.resolve(response); // Or handle it in any other way you prefer
    } 
      else {
        return Promise.reject(error);
      }
    }
  );