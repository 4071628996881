import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from "react-redux";

const AuthGuard = () => {
    const [data] = useSelector((state) => state.signin || [] );
    const token = data?.access_token;
    const auth = ( token != null );
    return auth ? <Outlet/> : <Navigate to="/" />;
}

export default AuthGuard