import { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import debounce from 'lodash.debounce'; // Install lodash.debounce if not already
import { showToast } from "./showToast";

const useDataFetching = (fetchDataForPlatform, endPoint, title) => {
  const location = useLocation();

  const [state, setState] = useState({
    data: [],
    isError: false,
    isLoading: false,
    isRefetching: false,
    rowCount: 0,
  });

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    selectFillter: "",
    search: "",
  });

  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  // Effect to clear global filter when endPoint changes
  useEffect(() => {
    setGlobalFilter({ selectFillter: "", search: "" });
  }, [endPoint, title]);

  const fetchData = useCallback(debounce(async () => {
    if (state.isLoading || state.isRefetching) return; 
    setState((prevData) => ({
      ...prevData,
      isLoading: !prevData.data.length,
      isRefetching: prevData.data.length > 0,
    }));

    const addParamIfExists = (params, key, value) => {
      if (value) {
        params[key] = value;
      }
    };

    const params = {};
    if (location?.pathname !== "/roleList") {
      addParamIfExists(params, "filter", globalFilter?.selectFillter);
    }
    addParamIfExists(
      params,
      location?.pathname === "/roleList"
        ? globalFilter?.selectFillter
        : location?.pathname === "/shiftlist"
        ? globalFilter?.selectFillter
        : location?.pathname === "/attendanceReports"
        ? globalFilter?.selectFillter
        : location?.pathname === "/getLockReports"
        ? globalFilter?.selectFillter
        : location?.pathname === "/getTrespassing"
        ? globalFilter?.selectFillter
        : location?.pathname === "/getfire"
        ? globalFilter?.selectFillter
        : location?.pathname === "/getpanicmode"
        ? globalFilter?.selectFillter
        : location?.pathname === "/getsalaryreport"
        ? globalFilter?.selectFillter
        : "search",

      location?.pathname === "/roleList" &&
        (globalFilter?.search.toLocaleLowerCase() === "active" ||
          globalFilter?.search.toLocaleLowerCase() === "inactive")
        ? globalFilter?.search.toLocaleLowerCase() === "active"
          ? 1
          : globalFilter?.search.toLocaleLowerCase() === "inactive"
        : 0
        ? globalFilter?.search
        : globalFilter?.search
    );

    params.page = pagination?.pageIndex + 1;
    params.limit = pagination?.pageSize;
    if (location?.pathname === "/getTrespassing") {
      params.alert_type = "trespassing";
    } else if (location?.pathname === "/getfire") {
      params.alert_type = "fire";
    } else if (location?.pathname === "/getpanicmode") {
      params.alert_type = "panic mode";
    } else {
      delete params.alert_type;
    }

    try {
      const response = await fetchDataForPlatform(params, endPoint);
      const responseData = response?.data?.data || response?.data;
      if ((!responseData.error && !responseData.status) || (responseData?.data !== undefined && responseData?.data !== null && responseData?.totalRecords  !== null) ) {
        const totalRows = response.data.totalRecords || response.totalRecords;
        setState((prevData) => ({
          ...prevData,
          data: responseData,
          rowCount: totalRows,
          isError: false,
          isLoading: false,
          isRefetching: false,
        }));
      } else {
        // showToast(response?.data?.message, "error")
        setState((prevData) => ({
          ...prevData,
          data:[],
          isError: false,
          isLoading: false,
          isRefetching: false,
        }));
      }
    } catch (error) {
      setState((prevData) => ({
        ...prevData,
        isError: true,
        isLoading: false,
        isRefetching: false,
      }));
    }
  }, 500), [globalFilter, pagination, fetchDataForPlatform, endPoint]);

  const manualFetchData = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData, location.pathname]);

  return {
    state,
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    pagination,
    setPagination,
    manualFetchData,
  };
};

export default useDataFetching;
