import { createSlice } from "@reduxjs/toolkit";

const Signin = createSlice({
  name: "Signin",
  initialState: [],
  reducers: {
    signin(state, action) {
      state.push(action.payload);
    },
    signOut(state, action) {
      return []; // Clear the state completely
    },
  },
});

export default Signin.reducer;
export const { signin, signOut } = Signin.actions;
