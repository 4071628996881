import SidebarLayout from './SidebarLayout';
const AdminLayout = ({ children }) => {
    return (
        <>
            <div class="wrapper">
                <SidebarLayout />
                { children }
            </div>
        </>
    )
}
export default AdminLayout;