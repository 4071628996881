import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { Eye, EyeOff } from "feather-icons-react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signin } from "../../store/slices/LoginSlice";
import { showToast } from "../../hooks/showToast";
import { axiosInstance } from "../../utils/commonInstance";

const LoginSchema = yup.object().shape({
  username: yup.string().required("Please enter your Username"),
  password: yup.string().required("Please enter your Password"),
});

const Login = ({ title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [eyeStatus, setEyeStatus] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    mode: "all",
    resolver: yupResolver(LoginSchema),
  });
  const handleSubmitClick = () => {
    handleSubmit(onSubmit)();
  };
  const onSubmit = async (data) => {
    try {
      // const response = await axios.post(process.env.REACT_APP_BACKEND_API_URL + "login", {...data,'mode_of_login':'Web'})
      const response = await axiosInstance.post("login", {
        ...data,
        mode_of_login: "Web",
      });
      if (response?.data?.error) {
        showToast(response?.data?.message, "error");
        return false;
      }
      showToast(response?.data?.message, "success");
      localStorage.setItem("accessToken", response.data.access_token);
      const dispatchData = await response.data;
      localStorage.setItem("role_id", response.data.role_id);
    //   console.log("ttttttttttt", response.data.role_id)
      dispatch(signin(dispatchData));
      navigate("/dashboard");
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
  };

  return (
    <>
      <section className="login-section">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-sm-11 col-12">
              <div className="wrap d-md-flex">
                <div className="img"></div>
                <div className="login-wrap p-4 p-lg-5 w-50 position-relative">
                  <h3 className="mb-4 fw-bold">{title}</h3>
                  <form className="js-loginForm login-form">
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Username
                      </label>
                      <Controller
                        name="username"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className={`form-control ${
                              errors.username ? "is-invalid" : ""
                            }`}
                            placeholder="Email / Mobile No."
                            onBlur={() => {
                              field.onBlur();
                            }}
                          />
                        )}
                      />
                      {errors.username && (
                        <div
                          className="invalid-feedback"
                          style={{ position: "absolute" }}
                        >
                          {errors.username.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="label" htmlFor="password">
                        Password
                      </label>
                      <div className="position-relative">
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type={eyeStatus ? "text" : "password"}
                              className={`form-control pe-5 ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              onBlur={() => {
                                field.onBlur();
                              }}
                              placeholder="Password"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  handleSubmitClick();
                                }
                              }}
                            />
                          )}
                        />
                        <button
                          type="button"
                          className="passforget border-0 bg-transparent"
                          onClick={() => setEyeStatus(!eyeStatus)}
                        >
                          {eyeStatus ? <Eye /> : <EyeOff />}
                        </button>
                        {errors.password && (
                          <div
                            className="invalid-feedback"
                            style={{ position: "absolute" }}
                          >
                            {errors.password.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <button
                        onClick={() => handleSubmitClick()}
                        type="button"
                        className="btn btn-lg theme-btn w-100"
                      >
                        {title}
                      </button>
                    </div>
                    <div className="form-group d-flex justify-content-end flex-wrap">
                      {/* <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="flexCheckChecked" value="option1" checked>
                                                <label className="form-check-label" htmlFor="flexCheckChecked">Remember Me</label>
                                            </div> */}
                      <div className="text-center">
                        <NavLink
                          to="/forgot-password"
                          className="text-danger fw-bold"
                        >
                          Forgot Password
                        </NavLink>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
