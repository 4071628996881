import { combineReducers } from 'redux';
import Signin from "./slices/LoginSlice";
import commonFunctionSlice from './slices/commonFunctionSlice';

const rootReducer = combineReducers({
  signin: Signin,
  CommonFunction: commonFunctionSlice
});

export default rootReducer;
