import HeaderLayout from "../../layouts/admin/HeaderLayout";
import FooterLayout from "../../layouts/admin/FooterLayout";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../utils/commonInstance";
import {
Shield, Calendar,AlertOctagon, AlertTriangle
} from "feather-icons-react";
const Dashboard = ({ title }) => {
  const [dashBoardData, setDashBoardData] = useState({})
  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axiosInstance.get(`web/dashboardData`);
      setDashBoardData(response?.data);
      // ...
    }
    fetchData();
  }, []);



  const currentDate = new Date();

  // Function to format the date as DD-MM-YYYY
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Function to format the time as hh:mm AM/PM
  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${hours}:${minutes} ${ampm}`;
  };

  const formattedDate = formatDate(currentDate);
  const formattedTime = formatTime(currentDate);



  return (
    <>
      <div className="main">
        <HeaderLayout title={title} />
        <main className="content">
          <div className="container-fluid p-0">
            <div className="card">
              <div className="card-body">
                <h3>
                  Welcome,
                  <span className="text-danger fw-bold">{dashBoardData?.username}</span>
                </h3>
                <div className="d-flex gap-3">
                  <span className="d-inline-flex align-items-center gap-1">
                    <i
                      className="align-middle mr-1"
                      data-feather="calendar"
                    ></i>
                    {formattedDate}
                  </span>
                  <span className="d-inline-flex align-items-center gap-1">
                    <i className="align-middle" data-feather="clock"></i> {formattedTime}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="w-100">
                  <div className="row">
                    <div className="col-xl-3 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <h5 className="card-title">Total Security</h5>
                            </div>

                            <div className="col-auto">
                              <div className="stat text-primary">
                                <Shield/>
                              </div>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">{dashBoardData?.total_user}</h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <h5 className="card-title">Present/absent</h5>
                            </div>

                            <div className="col-auto">
                              <div className="stat text-primary">
                               <Calendar />
                              </div>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">
                            <span>{dashBoardData?.total_present}</span>
                            <span> / </span>{dashBoardData?.total_absent}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <h5 className="card-title">Fire Alert</h5>
                            </div>

                            <div className="col-auto">
                              <div className="stat text-primary">
                              <AlertOctagon/>
                              </div>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">{dashBoardData?.total_fire_alert}</h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <h5 className="card-title">Panic Alert</h5>
                            </div>

                            <div className="col-auto">
                              <div className="stat text-primary">
                               <AlertTriangle/>
                              </div>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">{dashBoardData?.total_trespassing_alert}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <FooterLayout />
      </div>
    </>
  );
};
export default Dashboard;
