import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from "react-redux";

const BeforeAuthGuard = () => {
    
    const [data] = useSelector((state) => state.signin || [] );
    const token = data?.access_token;
    const auth = ( token != null );
    return auth ? <Navigate to="/dashboard" /> : <Outlet/> ;
}

export default BeforeAuthGuard