import AppRouter from "./router";
import { ToastContainer } from "react-toastify";


function App() {
  return (
    <>
      <ToastContainer autoClose={500} closeButton={false} />
      <AppRouter />    
    </>
  );
}

export default App;
