import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { showToast } from "../../hooks/showToast";
import { axiosInstance } from "../../utils/commonInstance";
import { checkObjectForNullUndefinedOrEmpty } from "../../utils/commonFunction";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import * as moment from "moment";
import { BackButtonModal } from "../../components/BackButtonModal";

const schema = yup.object().shape({
  company_id: yup.string().required("Please select Company"),
  location_id: yup.string().required("Please select Location"),
  startTime: yup.string().required("Please select Start Time"),
  endTime: yup.string().required("Please select End Time"),
  shift_name: yup
    .string()
    .required("Please enter Shift Name")
    .max(30, "Maximum 30 characters is allowed only")
    .test("no-spaces-at-ends", "Please Enter Valid Input", (value) => {
      if (!value) return true; // Skip empty values (use required() to handle this)
      return value.trim() === value;
    }),
});

const ShiftForm = ({
  toggleFormFillrter,
  manualFetchData,
  setGlobalFilter,
  isView,
  isEdit,
  previousData,
}) => {
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleModalopen = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
    }));
  };

  const [companyOption, setCompanyOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axiosInstance.get(`web/companydropdown`);
      setCompanyOption(response?.data?.data);

      // ...
    }
    fetchData();
  }, []);

  const selectedCompanyId = watch("company_id");
  useEffect(() => {
    if (selectedCompanyId) {
      // Fetch locations based on selected company_id if needed
      async function fetchLocations() {
        const response = await axiosInstance.get(
          `web/locationdata/${selectedCompanyId}`
        );
        if (!response?.data?.error) {
          setLocationOption(response?.data?.data);
        } else {
          setLocationOption([])
          showToast(response?.data?.message, "error");
        }
      }
      fetchLocations();
    }
  }, [selectedCompanyId]);

  const onSubmit = async (data) => {
    const params = {
      company_id: data?.company_id,
      location_id: data?.location_id,
      shift_name: data?.shift_name,
      start_time: moment(data?.startTime).format("hh:mmA"),
      end_time: moment(data?.endTime).format("hh:mmA"),
      shift_status: data?.checkpoint_status,
    };
    try {
      const dynamicEndPoint = isEdit
        ? `web/updateshift/${previousData?.id}`
        : "web/saveshift";
      const method = isEdit ? "put" : "post";

      const response = await axiosInstance({
        method: method,
        url: dynamicEndPoint,
        data: {
          ...params,
        },
      });

      if (response?.data?.statusCode !== 200) {
        showToast(response?.data?.message, "error");
        return false;
      }

      await showToast(response?.data?.message, "success");
      await manualFetchData();
      await toggleFormFillrter();
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (isView || isEdit) {
      setValue("company_id", previousData?.company_id);
      setValue("location_id", previousData?.location_id);
      setValue("shift_name", previousData?.shift_name);
      setValue("startTime", moment(previousData?.start_time, "hh:mmA")); // Assuming your time format is "HH:mm"
      setValue("endTime", moment(previousData?.end_time, "hh:mmA")); // Assuming your time format is "HH:mm"
      setValue("checkpoint_status", previousData?.is_active);
    }
  }, [isEdit, isView, previousData, setValue, companyOption]);

  let result = checkObjectForNullUndefinedOrEmpty(watch());

  return (
    <>
      <section class="userlist-newuser content mobile">
        <div class="d-flex justify-content-end align-items-center mb-4 gap-3 flex-wrap">
          <h3 class="mb-0">
            <strong>
              {isEdit ? "Update" : isView ? "View" : "Add"} Shift Details
            </strong>
          </h3>
          <button
            onClick={() =>
              result && !isView ? handleModalopen() : toggleFormFillrter()
            }
            class="btn btn-lg btn-primary px-md-5 px-4 addlistuser"
          >
            Back
          </button>
        </div>

        <div class="user-details">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="card">
              <div class="row card-body justify-content-around py-md-5 py-4 align-items-center">
                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="company_id" className="form-label">
                      Select Company
                      <span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="company_id"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={isView}
                          onChange={(e) => {
                            // Manually call the registered onChange method to ensure the value of company_id is updated
                            field.onChange(e);
                            setValue("location_id", "");
                          }}
                        >
                          <option value="" disabled>
                            Company
                          </option>
                          {companyOption?.map((ele) => (
                            <option value={ele?.id}>{ele?.company_name}</option>
                          ))}
                        </select>
                      )}
                    />

                    {errors?.company_id && (
                      <p className="text-danger">
                        {errors?.company_id?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="location_id" className="form-label">
                      Select Location<span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="location_id"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={isView}
                        >
                          <option value="" disabled>
                            Location
                          </option>
                          {locationOption?.map((ele) => (
                            <option value={ele?.id}>
                              {ele?.location_name}
                            </option>
                          ))}
                        </select>
                      )}
                    />

                    {errors?.location_id && (
                      <p className="text-danger">
                        {errors?.location_id?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="shift_name" className="form-label">
                      Shift Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="shift_name"
                      placeholder="Enter Shift Name"
                      {...register("shift_name")}
                      disabled={isView}
                    />
                    {errors.shift_name && (
                      <p className="text-danger">{errors.shift_name.message}</p>
                    )}
                  </div>
                </div>

                <div class="col-lg-5 col-md-6">
                  <div class="form-group mb-3">
                    <label for="code" class="form-label">
                      Start Time<span class="text-danger">*</span>
                    </label>
                    <Controller
                      name="startTime"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TimePicker
                          disabled={isView}
                          showSecond={false}
                          className="form-control timepicker-input py-2 timepicker"
                          use12Hours
                          inputReadOnly
                          format={"hh:mm A"}
                          value={value}
                          onChange={(time) => {
                            onChange(time); // Update the form value
                          }}
                        />
                      )}
                    />
                    <p className="text-danger">{errors.startTime?.message}</p>
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <div class="form-group mb-3">
                    <label for="code" class="form-label">
                      End Time<span class="text-danger">*</span>
                    </label>

                    <Controller
                      name="endTime"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TimePicker
                          disabled={isView}
                          showSecond={false}
                          className="form-control timepicker-input py-2 timepicker"
                          use12Hours
                          inputReadOnly
                          format={"hh:mm A"}
                          value={value}
                          onChange={(time) => {
                            onChange(time); // Update the form value
                          }}
                        />
                      )}
                    />

                    <p className="text-danger">{errors.endTime?.message}</p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mt-md-3 mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        id="checkpoint_status"
                        defaultChecked
                        {...register("checkpoint_status")}
                        disabled={isView}
                      />
                      <label
                        className="form-check-label mb-0"
                        htmlFor="checkpoint_status"
                      >
                        IS ACTIVE
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-12 text-center mt-lg-5 mt-4">
                  <button
                    class="btn btn-lg btn-primary px-5"
                    type="submit"
                    disabled={isView}
                  >
                    {isEdit ? "UPDATE" : "SUBMIT"}
                  </button>
                  <button
                    class="btn btn-lg btn-primary px-5 mx-xl-4"
                    type="button"
                    disabled={isView || isEdit}
                    onClick={() => {
                      reset();
                      resetField("startTime");
                      resetField("endTime");
                      setLocationOption([]);
                    }}
                  >
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <BackButtonModal
          isOpen={modal?.isOpen}
          handleClose={handleClose}
          toggleFormFillrter={toggleFormFillrter}
        />
      </section>
    </>
  );
};

export default ShiftForm;
