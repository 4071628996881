export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const flattenObject = (obj, parentKey = "", res = {}) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}_${key}` : key;
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], newKey, res);
      } else {
        res[newKey] = obj[key];
      }
    }
  }
  return res;
};

function isNullUndefinedOrEmpty(value) {
  // Check if the value is null, undefined, an empty string, an empty array, or an empty object
  if (value !== null || value !== undefined || value !== "") {
    return true;
  }
  if (Array.isArray(value) && value?.length > 0) {
    return true;
  }
  if (
    typeof value === "object" &&
    !Array.isArray(value) &&
    Object.keys(value).length > 0
  ) {
    return true;
  }
  return false;
}

export function checkObjectForNullUndefinedOrEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key];
      // Ignore boolean values
      if (typeof value !== "boolean" && isNullUndefinedOrEmpty(value)) {
        return true;
      }
    }
  }
  return false;
}


export function extractKeysFromArray(array, keys) {
  return (
    array?.length &&
    array.map((item) => {
      const extractedItem = {};
      keys.forEach((key) => {
        // Convert the key to lower case and remove spaces
        const formattedKey = key.replace(/\s/g, "").toLowerCase();

        // Find the corresponding key in the item
        const itemKey = Object.keys(item).find(
          (k) => k.toLowerCase().replace(/\s/g, "") === formattedKey
        );

        // If the key exists in the item, add it to the extracted item
        // Otherwise, assign a blank value (you can change this to any default value)
        extractedItem[key] = itemKey ? item[itemKey] : "";
      });
      return extractedItem;
    })
  );
}


export function changeArrayKeys(originalArray, keyMapping, keysToRemove = []) {
  return (
    originalArray?.length &&
    originalArray?.map((item) => {
      const newItem = {};
      Object.keys(item).forEach((oldKey) => {
        // Skip keys that need to be removed
        if (keysToRemove.includes(oldKey)) {
          return;
        }

        const newKey = keyMapping[oldKey] || oldKey;
        newItem[newKey] = mapValue(item[oldKey]);
      });
      return newItem;
    })
  );
}

function mapValue(value) {
  // Customize this mapping based on your specific needs
  if (typeof value === "boolean") {
    return value ? "Active" : "Inactive";
  }
  return value;
}



export function createHeaderMap(columns, excludeKeys = []) {
  return columns.reduce((acc, column) => {
    if (!excludeKeys.includes(column.accessorKey)) {
      if (column.header !== "Sr. No.") {
        acc["Sr. No."] = column.header;
      } else {
        acc[column.accessorKey] = column.header;
      }
    }
    return acc;
  }, {});
}


export function addSerialNumber(data, pagination) {
  const { pageIndex, pageSize } = pagination;
  const startIndex = pageIndex * pageSize;

  return (
    data?.length &&
    data.map((item, index) => {
      return { "Sr. No": startIndex + index + 1, ...item };
    })
  );
}



export const flattenObjectForReportsPage = (obj, parentKey = '', result = {}) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}_${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          flattenObjectForReportsPage(obj[key], newKey, result);
        } else {
            result[newKey] = obj[key];
        }
    }
}
return result;
};


export function transformData(data) {
  return data.map(item => {
      // Extract user and shift properties
      const { user, Shift, ...rest } = item;

      // Lift all properties from user to rest
      if (user) {
          Object.assign(rest, user);
      }

      // Lift all properties from shift to rest
      if (Shift) {
          Object.assign(rest, Shift);
      }

      return rest;
  });
}