
import React, {useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { showToast } from "../../hooks/showToast";
import Otp from "../../components/Otp";
import { Eye,EyeOff } from 'feather-icons-react';
import { useNavigate,NavLink } from "react-router-dom";
import { axiosInstance } from "../../utils/commonInstance"

const ForgotPasswordSchema = yup.object().shape({
    username: yup.string().required("Please enter your Username"),
});

const ResetPasswordSchema = yup.object().shape({
    password: yup.string()
    .required('Please enter a new password.')
    .min(8, 'Password length must be between 8-15 characters.')
    .max(15, 'Password length must be between 8-15 characters.')
    .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,15}$/, 'Password should contain atleast 1 symbol'),
    confirm_password: yup.string()
    .required('Please enter confirm password.')
    .oneOf([yup.ref('password'), null], 'Please enter same password')
});

const ForgotPassword = ({title}) => {
    const navigate = useNavigate();
    const [showOtp,setShowOtp] = useState(false);
    const [showResendText, setShowResendText] = useState(true);
    const intervals = [120, 120, 120, 120, 120];
    const maxAttempts = 5; // Maximum number of attempts
    const [timer, setTimer] = useState(intervals[0]);
    const [attempts, setAttempts] = useState(1);
    const [otpError,setOtpError] = useState('');
    const [userData,setUserData] = useState();
    const [showPasswordChange,setShowPasswordChange] = useState(false);
    const [eyeStatus, setEyeStatus] = useState(false);
    const [newPassworsEyeStatus, setNewPassworsEyeStatus] = useState(false);
    const [confirmPassworsEyeStatus, setConfirmPassworsEyeStatus] = useState(false);

    useEffect(() => {
        let interval;
        if (attempts <= intervals.length && timer > 0) {
          interval = setInterval(() => {
            setTimer((prevTimer) =>
              prevTimer > 0 ? prevTimer - 1 : intervals[attempts]
            );
            if (timer === 0) {
              clearInterval(interval);
              setAttempts((prevAttempts) => prevAttempts + 1);
              // Reset the timer based on the attempts or set to default if max attempts reached
              setTimer(attempts < intervals.length ? intervals[attempts] : 120);
            }
          }, 1000);
        }
        return () => clearInterval(interval);
    }, [timer, attempts, intervals]);


    const initValue = {
        username: "",
    };
    const resetPassword = {
        password: "",
        confirm_password: "",
    };
    
    const {
        control,
        handleSubmit,
        formState: { errors },
      } = useForm({
        defaultValues: !showPasswordChange ? initValue : resetPassword,
        mode: "all",
        resolver: yupResolver(!showPasswordChange ? ForgotPasswordSchema : ResetPasswordSchema),
    })
    const handleSubmitClick = () =>{
        handleSubmit(onSubmit)()
    }
    const onSubmit = async (data) => {
        
            try{
                if(!showPasswordChange){
                    //const response = await axios.post(process.env.REACT_APP_BACKEND_API_URL + "forgotpassword", {...data})
                    const response = await axiosInstance.post('forgotpassword',{...data});
                    if (response?.data?.error) {
                        showToast( response?.data?.message ,"error");
                        return false;
                    }
                    showToast(response?.data?.message ,"success");
                    setUserData(data);
                    setShowOtp(true);
                }else{
                    //const response = await axios.post(process.env.REACT_APP_BACKEND_API_URL + "resetpassword", {...data,...userData})
                    const response = await axiosInstance.post('resetpassword',{...data,...userData});
                    if (response?.data?.error) {
                        showToast( response?.data?.message ,"error");
                        return false;
                    }
                    showToast(response?.data?.message ,"success");
                    navigate('/')
                }
            }catch (error){
                showToast( error?.data?.message ,"error");
            }
    };
    const handleResendClick = () => {
        if (attempts <= maxAttempts) {
          setAttempts((prevAttempts) => prevAttempts + 1);
          setTimer(attempts < intervals.length ? intervals[attempts] : 10);
          handleSubmit(onSubmit)()
          //showToast("OTP Send to Registered Mobile Number", "success");
        }
        if(attempts === intervals.length){
            setShowResendText(false);
        }
    };
    const handleOtpSubmitClick = async () =>{
        var otpInputFields = document.getElementsByClassName("otpInput");
        let  userOTP = '';
        for (var i = 0; i < otpInputFields.length; i++) {
            userOTP += otpInputFields[i].value;
        }
        if(userOTP == ''){
            setOtpError('Please enter your OTP');
            return false;
        }
        setOtpError('');
        try{
           // const response = await axios.post(process.env.REACT_APP_BACKEND_API_URL + "verifyotp", {...userData,'otp':userOTP})
            const response = await axiosInstance.post('verifyotp',{...userData,'otp':userOTP});
            if (response?.data?.error) {
                showToast( response?.data?.message ,"error");
                return false;
            }
            showToast(response?.data?.message ,"success");
            setShowPasswordChange(true)
            
        }catch (error){
            showToast( error?.data?.message ,"error");
        }

    }
    const secondsToHHMM = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
          remainingSeconds
        ).padStart(2, "0")}`;
        return formattedTime;
    };
    return (
        <>
            { !showOtp ? 
                <section className="forgot-password">
                    <div className="login-section">   
                        <div className="container-lg">
                            <div className="row justify-content-center">
                                <div className="col-sm-11 col-12">
                                    <div className="wrap d-md-flex">
                                        <div className="img"></div>
                                        <div className="login-wrap p-4 p-lg-5 w-50 position-relative">
                                            <h3 className="mb-4 fw-bold">{title}</h3>
                                            <form action="#" className="login-form">
                                                <div className="form-group mb-4">
                                                    <label className="label" htmlFor="name">Username</label>
                                                    <Controller
                                                        name="username"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <input
                                                            {...field}
                                                            type="text"
                                                            className={`form-control ${
                                                                errors.username ? "is-invalid" : ""
                                                            }`}
                                                            placeholder="Email / Mobile No."
                                                            />
                                                        )}
                                                    />
                                                    {errors.username && (
                                                        <div className="invalid-feedback" style={{ position: "absolute" }}>
                                                            {errors.username.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <button onClick={() => handleSubmitClick()}  type="button" className="btn btn-lg theme-btn w-100">Verify & Proceed</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </section> 
                : 
                    showPasswordChange ? 
                        <section className="reset-password">
                            <div className="login-section">   
                                <div className="container-lg">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-11 col-12">
                                            <div className="wrap d-md-flex">
                                                <div className="img"></div>
                                                <div className="login-wrap p-4 p-lg-5 w-50 position-relative">
                                                    <h3 className="mb-4 fw-bold">Reset Password</h3>
                                                    <form action="#" className="reset-form">
                                                        <div className="login-form">
                                                            <div className="form-group mb-3">
                                                                <label className="label" htmlFor="name">New Password</label>
                                                                <div className="position-relative">
                                                                    <Controller
                                                                        name="password"
                                                                        control={control}
                                                                        render={({field} )=>(
                                                                            <input 
                                                                                {...field}
                                                                                type={!newPassworsEyeStatus ? "password" : "text"}
                                                                                className={`form-control passwordInput pe-5 
                                                                                    ${errors.password ? "is-invalid" :""}`}
                                                                                placeholder="New Password"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <button onClick={() => setNewPassworsEyeStatus(!newPassworsEyeStatus)} type="button" className="passforget border-0 bg-transparent focus-only" > 
                                                                        {newPassworsEyeStatus ? <Eye /> : <EyeOff />}
                                                                    </button>
                                                                    {errors.password && (
                                                                        <div className="invalid-feedback" style={{ position: "absolute" }}>
                                                                            {errors.password.message}
                                                                        </div>
                                                                    )}
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-4">
                                                                <label className="label" htmlFor="password">Confirm Password</label>
                                                                <div className="position-relative">
                                                                    <Controller
                                                                        name="confirm_password"
                                                                        control={control}
                                                                        render={({field})=>(
                                                                            <input 
                                                                                {...field}
                                                                                type={!confirmPassworsEyeStatus ? "password" : "text"}
                                                                                placeholder="Confirm Password"
                                                                                className={`form-control confirmPasswordInput pe-5
                                                                                    ${errors?.confirm_password ? "is-invalid":""}`}
                                                                            />
                                                                        )}
                                                                    />  
                                                                    <button type="button" onClick={() => { setConfirmPassworsEyeStatus(!confirmPassworsEyeStatus)}} className="passforget border-0 bg-transparent focus-only" > 
                                                                        {confirmPassworsEyeStatus ? <Eye /> : <EyeOff />}
                                                                    </button>
                                                                    {errors.confirm_password && (
                                                                         <div className="invalid-feedback" style={{ position: "absolute" }}>
                                                                            {errors?.confirm_password?.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <button onClick={() => handleSubmitClick()}  type="button" className="btn btn-lg theme-btn w-100">Reset Password</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </section>
                :
                    <section className="otp-screen">
                        <div className="login-section">   
                            <div className="container-lg">
                                <div className="row justify-content-center">
                                    <div className="col-sm-11 col-12">
                                        <div className="wrap d-md-flex justify-content-center">
                                            <div className="img"></div>
                                            <div className="login-wrap p-4 p-lg-5 w-50 position-relative">
                                                <h3 className="mb-4 fw-bold text-center">Verify OTP</h3>
                                                <h5 className="fw-medium mb-4 text-center">Enter Your One Time Password</h5>
                                                <div className="otp-field mb-2 d-flex gap-3 justify-content-center align-items-center flex-wrap">
                                                    <Otp otpLength={4}/>
                                                </div>
                                                <div className="text-danger">
                                                    {otpError}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <button onClick={() => handleOtpSubmitClick()}  type="button" className="btn btn-lg theme-btn w-100">Verify & Proceed</button>
                                                </div>
                                                { !timer ?
                                                    <button onClick={()=>handleResendClick()} className="text-danger fw-bold border-0 bg-transparent px-1">Resend OTP</button>
                                                    :   showResendText ?
                                                            <p className="text-dark text-center mb-0">Didn't receive OTP code? Resend in {secondsToHHMM(timer)}</p>
                                                        :   <p className="text-dark text-center mb-0">Reach Your Maximum Limit. Try after some time.</p> 
                                                    
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            }
        </>
    )
}
  
export default ForgotPassword;