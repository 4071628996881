import React from "react";
import { NavLink } from "react-router-dom";
const FooterLayout = () => {
    // return (
    //     <>
    //         <footer className="footer">
    //             <div className="container-fluid">
    //                 <div className="row text-muted">
    //                     <div className="col-6 text-start">
    //                         <p className="mb-0">
    //                             <NavLink className="text-muted">
    //                                 <strong>Security Guard</strong>
    //                             </NavLink>
    //                             &copy;
    //                         </p>
    //                     </div>
    //                     <div className="col-6 text-end">
    //                         <ul className="list-inline">
    //                             <li className="list-inline-item">
    //                                 <NavLink className="text-muted" href="#">
    //                                     Support
    //                                 </NavLink>
    //                             </li>
    //                             <li className="list-inline-item">
    //                                 <NavLink className="text-muted" href="#">
    //                                     Help Center
    //                                 </NavLink>
    //                             </li>
    //                             <li className="list-inline-item">
    //                                 <NavLink className="text-muted" href="#">
    //                                     Privacy
    //                                 </NavLink>
    //                             </li>
    //                             <li className="list-inline-item">
    //                                 <NavLink className="text-muted" href="#">
    //                                     Terms
    //                                 </NavLink>
    //                             </li>
    //                         </ul>
    //                     </div>
    //                 </div>
    //             </div>
    //         </footer>
    //     </>
    // );
};
export default FooterLayout;
